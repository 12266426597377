"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _elementUi = require("element-ui");
var _errorParse = require("@/utils/error-parse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!(0, _validate.notEmptyString)(value)) {
        callback(new Error('Please enter user name'));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      remember_me: false,
      serverOnline: true,
      pollingInterval: null,
      abortController: new AbortController()
    };
  },
  watch: {
    $route: {
      handler: async function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  mounted() {
    this.startServerStatusCheck();
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval);
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        if (this.$refs.password) {
          this.$refs.password.focus();
        }
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch('user/login', this.loginForm).then(data => {
            if (this.remember_me) {
              return this.$store.dispatch('user/remember', data.refresh_token);
            } else {
              return Promise.resolve();
            }
          }).then(() => {
            return this.$router.push({
              path: this.redirect || '/'
            });
          }).catch(err => {
            // console.log(err, (err.response && err.response.data && parseError(err.response.data.message)))

            if (err.headers && err.headers['retry-after']) {
              (0, _elementUi.Message)({
                message: 'Too Many Attempt, Please Retry After ' + err.headers['retry-after'] + 's',
                type: 'error',
                duration: 5 * 1000
              });
            } else if (err) {
              (0, _elementUi.Message)({
                message: (0, _errorParse.parseError)(err) || err.message || 'Unknown Error occurred',
                type: 'error',
                duration: 5 * 1000
              });
            }
            this.loading = false;
          });
        } else {
          // console.log('error submit!!')
          return false;
        }
      });
    },
    startServerStatusCheck() {
      // Check server status immediately
      this.checkServerStatus();

      // Check server status every 5 seconds (adjust the interval as needed)
      this.pollingInterval = setInterval(this.checkServerStatus, 5000);
    },
    checkServerStatus() {
      this.abortController.abort(); // Cancel the previous request
      if (navigator.onLine) {
        this.abortController = new AbortController(); // Create a new AbortController
        fetch(process.env.VUE_APP_BASE_API, {
          method: 'HEAD',
          signal: this.abortController.signal
        }).then(response => {
          if (response.ok && response.status === 200) {
            // Server is online
            this.serverOnline = true;
          } else if (navigator.onLine) {
            // Server is offline
            this.serverOnline = false;
          }
        }).catch(error => {
          // Server is offline or error occurred
          if (error.name === 'AbortError' && error.message === 'The user aborted a request.') {
            // Request was aborted
            // console.log('Request aborted by the user.')
            return;
          }
          if (navigator.onLine) {
            this.serverOnline = false;
            console.error(error, error.name, error.message);
          }
        });
      }
    }
  }
};
exports.default = _default;