"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _vuex = require("vuex");
var _leave = require("@/api/leave");
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      periods: [{
        name: '上午',
        value: 'AM'
      }, {
        name: '下午',
        value: 'PM'
      }],
      datapickertype: 'daterange',
      rules: {
        leave_id: {
          required: true,
          message: '請選擇一個假期種類'
        },
        total: {
          required: true,
          message: '請填寫時長'
        }
      },
      leaveUnit: '',
      leaveUnitDisplay: '時長',
      remainHoildayDisplay: '',
      leaveUnitStep: 1,
      leaveMax: 0,
      leaveMin: 0,
      uploadFileLimit: 3,
      formUpload: {
        action: '',
        data: {}
      },
      uploadValid: true,
      history: {
        leave_id: null,
        user_id: null,
        datefrom: '',
        dateto: '',
        timefrom: '',
        timeto: '',
        periodfrom: 'AM',
        periodto: 'AM',
        status: 'pending',
        id: 0,
        uuid: '',
        total: 0,
        remark: '',
        attachment: []
      },
      leaves: [],
      statuss: [{
        name: '審核中',
        value: 'pending'
      }, {
        name: '批准',
        value: 'approved'
      }, {
        name: '拒絕',
        value: 'rejected'
      }]
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['user']),
    device() {
      return this.$store.state.app.device;
    },
    dateRange: {
      get() {
        return [this.history.datefrom, this.history.dateto];
      },
      set(range) {
        if (range !== null) {
          this.history.datefrom = range[0];
          this.history.dateto = range[1];
        } else {
          this.history.datefrom = '';
          this.history.dateto = '';
        }
      }
    }
  },
  async created() {
    this.history.user_id = this.user.uuid;
    if (this.$route.params.user_id) {
      this.history.user_id = this.$route.params.user_id;
    }
    await (0, _leave.getUserLeave)(this.history.user_id).then(response => {
      const data = response.data;
      this.leaves = [];
      for (let i = 0; i < data.length; i++) {
        const leave = data[i].leave;
        this.leaves.push({
          name: leave.name,
          value: leave.uuid,
          unit: leave.unit,
          quota: leave.quota,
          leaveUsed: leave.leaveUsed,
          used: data[i].used
        });
      }
    });
    if (this.$route.params.id) {
      await (0, _leave.getHistory)({
        apply_id: this.$route.params.id,
        listAll: true
      }).then(response => {
        const data = response.data;
        if (data.length > 0) {
          this.history = {
            id: data[0].id,
            uuid: data[0].uuid,
            leave_id: data[0].leave.uuid,
            user_id: data[0].user.uuid,
            datefrom: data[0].date_from,
            dateto: data[0].date_to,
            timefrom: (0, _moment.default)(data[0].date_from ? String(data[0].date_from) : undefined).format('HH:mm'),
            timeto: (0, _moment.default)(data[0].date_to ? String(data[0].date_to) : undefined).format('HH:mm'),
            periodfrom: data[0].periodfrom,
            periodto: data[0].periodto,
            total: data[0].total,
            status: data[0].status,
            remark: data[0].remark,
            attachment: []
          };
          this.changeUnit();
        }
      });
    }

    /*
    var today = new Date
    today.setDate(today.getDate() + 1)
    var year = today.getFullYear()
    var month = today.getMonth()+1
    if(parseInt(month) < 10){
      month = "0"+month
    }
    var day = today.getDate()
    if(parseInt(day) < 10){
      day = "0"+day
    }
    var d = year+"-"+month+"-"+day
    this.history.datefrom = d
    this.history.dateto = d
    */
  },

  methods: {
    format_date(value) {
      if (value) {
        return (0, _moment.default)(String(value)).format('YYYY-MM-DD');
      }
    },
    /*
    calculateBusinessDays(start, end){
       var start = new Date(start)
      var end = new Date(end)
       // initial total
      var totalBusinessDays = 0
       // normalize both start and end to beginning of the day
      start.setHours(0,0,0,0)
      end.setHours(0,0,0,0)
       var current = new Date(start)
      current.setDate(current.getDate() + 1)
      var day
      // loop through each day, checking
      while (current <= end) {
          day = current.getDay()
          if (day >= 1 && day <= 5) {
              ++totalBusinessDays
          }
          current.setDate(current.getDate() + 1)
      }
      return totalBusinessDays
    },
    */
    changeUnit() {
      let leave = null;
      if (this.history.leave_id !== null && this.history.leave_id !== '') {
        this.leaveMin = 0;
        for (let i = 0; i < this.leaves.length; i++) {
          leave = this.leaves[i];
          if (leave.value === this.history.leave_id) {
            this.leaveUnit = leave.unit;
            switch (leave.unit) {
              case 'full-day':
                this.leaveUnitDisplay = '時長/總日數';
                this.leaveUnitStep = 1;
                this.leaveMin = 1;
                break;
              case 'half-day':
                this.leaveUnitDisplay = '時長/總日數';
                this.leaveUnitStep = 0.5;
                this.leaveMin = 0.5;
                break;
              case 'hour':
                this.leaveUnitDisplay = '時長/總小時';
                this.leaveUnitStep = 1;
                this.leaveMin = 1;
                break;
              default:
                this.leaveUnitDisplay = '時長/總(天數/小時)';
                this.leaveUnitStep = 1;
                this.leaveMin = 1;
                break;
            }
            break;
          }
        }
      }
      this.calcTotal();
      this.remainHoildayDisplay = '';
      const quota = leave ? leave.quota : 0;
      if (quota > 0) {
        let leaveUsed = leave ? leave.leaveUsed : 0;
        if (this.$route.params.id) {
          // edit
          leaveUsed = leaveUsed - parseFloat(this.history.total);
        }
        this.remainHoildayDisplay = '剩餘 ' + (quota - leaveUsed) + ' 日';
      }
    },
    calcTotal() {
      const datefrom = (0, _moment.default)(this.history.datefrom).format('YYYY-MM-DD');
      const dateto = (0, _moment.default)(this.history.dateto).format('YYYY-MM-DD');
      const date1 = (0, _moment.default)(this.history.datefrom, 'YYYY-MM-DD');
      const date2 = (0, _moment.default)(this.history.dateto, 'YYYY-MM-DD');
      // var diff = this.calculateBusinessDays(datefrom,dateto)
      let diff = date2.diff(date1, 'days');
      if (this.leaveUnit === 'full-day') {
        if (diff > -1) {
          diff = diff + 1;
        }
      }
      if (this.leaveUnit === 'half-day') {
        if (this.history.periodfrom === this.history.periodto) {
          diff = diff + 0.5;
        } else if (this.history.periodfrom === 'AM' && this.history.periodto === 'PM') {
          diff = diff + 1;
        }
      }
      if (this.leaveUnit === 'hour') {
        const time1 = (0, _moment.default)(datefrom + ' ' + this.history.timefrom + ':00');
        const time2 = (0, _moment.default)(dateto + ' ' + this.history.timeto + ':00');
        let timediff = time2.diff(time1, 'minutes') / 60;
        if (timediff > 0) {
          if (diff === 0) {
            diff = timediff;
          } else {
            timediff = timediff - diff * 24;
            if (timediff < 0) {
              timediff = 0;
            }
            diff = diff * 10 + timediff;
          }
        } else {
          diff = timediff;
        }
      }
      this.history.total = diff;
    },
    onSubmit() {
      /*
      var param = {
        history : this.history,
      }
      */
      // console.log(param)

      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.$route.params.id) {
            (0, _leave.editApply)(this.history).then(() => {
              (0, _elementUi.Message)({
                message: 'Apply edited.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/leaves/summary`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              // console.log(err)
              this.loading = false;
            });
          } else {
            (0, _leave.createApply)(this.history).then(() => {
              (0, _elementUi.Message)({
                message: 'Apply submitted.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/leaves/summary`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              this.loading = false;
            });
          }
        }
      });
    },
    onCancel() {
      this.$router.push(`/leaves/summary`);
    },
    async beforeUpload(file) {
      // console.log('Before Upload')

      const isIMAGE = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
      const isLt1M = file.size / 1024 / 1024 < this.uploadFileLimit;
      if (!isIMAGE) {
        this.$message.error('只能上傳JPG/PNG/PDF');
      }
      if (!isLt1M) {
        this.$message.error('上傳文件大小不能超過 ' + this.uploadFileLimit + 'MB');
      }
      if (isIMAGE && isLt1M) {
        this.formUpload = await (0, _leave.getUploadUrl)(file.name);
      } else {
        this.formUpload = {
          action: '',
          data: {}
        };
      }
      return isIMAGE && isLt1M;
    },
    onFileChange() {
      // console.log('onFileChange')
    },
    onUploaded(response, file, fileList) {
      // console.log('Uploaded')

      const url = this.formUpload.action + this.formUpload.data.key.replace('${filename}', file.name);
      // this.history.attachment.push({file: file, url: url})
      this.history.attachment = fileList;
      fileList[fileList.length - 1].url = url;
      // console.log(this.history.attachment)
    },

    onError() {
      // console.log('Uplaod Failed')
    },
    handleExceed() {
      this.$message.error('只能上傳最多3個文件');
      return false;
    },
    onFileRemove(file, fileList) {
      /*
      for(var i=0;i<this.history.attachment.length;i++){
        var attachment = this.history.attachment[i]
        if(attachment.file === file){
          this.history.attachment.splice(i, 1)
          i--
        }
      }
      */
      this.history.attachment = fileList;
      // console.log(this.history.attachment)
    }
  }
};
exports.default = _default;