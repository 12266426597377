"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _duty = require("@/api/duty");
var _user = require("@/api/user");
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd'
var _default = {
  data() {
    return {
      list: null,
      datefrom: (0, _moment.default)().endOf('year').format('YYYY-MM-DD'),
      dateto: (0, _moment.default)().endOf('year').format('YYYY-MM-DD'),
      leaveUser: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  computed: {
    defaultDateRange: {
      get() {
        return [this.datefrom, this.dateto];
      },
      set(range) {
        this.datefrom = range[0];
        this.dateto = range[1];
      }
    }
  },
  async created() {
    // const currentDate = new Date()
    // this.datefrom = new Date(moment(currentDate).startOf('year').format('YYYY-MM-DD'))
    // this.dateto = new Date(moment(currentDate).endOf('year').format('YYYY-MM-DD'))

    (0, _user.getUser)(this.$route.params.user_id).then(leaveUser => {
      this.leaveUser = leaveUser.data.name;
    });
    this.fetchData();
  },
  methods: {
    query() {
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      (0, _duty.getPersonalOT)({
        leave_id: this.$route.params.id,
        user_id: this.$route.params.user_id,
        listAll: true,
        fromTime: this.datefrom,
        toTime: this.dateto
      }).then(response => {
        this.list = response.data;
      }).finally(() => {
        this.listLoading = false;
      });
    },
    editApply(uuid) {
      if (this.$route.params.user_id) {
        this.$router.push(`/users/${this.$route.params.user_id}/leaves/apply/edit/` + uuid);
      } else {
        this.$router.push(`/leaves/apply/edit/` + uuid);
      }
    },
    applyLeaveDate(valObj) {
      return valObj.date_from.split(' ')[0] + ' ' + valObj.periodfrom + ' - ' + valObj.date_to.split(' ')[0] + ' ' + valObj.periodto;
    }
  }
};
exports.default = _default;