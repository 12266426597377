"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _leave = require("@/api/leave");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      currentUser: null,
      leave: {
        id: null,
        name: '',
        description: '',
        employment_condition: '',
        employment_months: 0,
        unit: '',
        quota: 0,
        before_days: 0
      },
      buttonText: 'Add',
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        },
        description: {
          required: true,
          message: 'Description is required'
        }
      },
      conditions: [{
        name: '入職即可請假',
        value: 'any'
      }, {
        name: '轉正後才可請假',
        value: 'confirmed'
      }, {
        name: '自定義',
        value: 'custom'
      }],
      units: [{
        name: '按天請',
        value: 'full-day'
      }, {
        name: '按半天請',
        value: 'half-day'
      }, {
        name: '按小時請',
        value: 'hour'
      }],
      dayCountTypes: [{
        name: '按工作日計算請假時長',
        value: 'working-day'
      }, {
        name: '按月曆日計算請假時長',
        value: 'calendar-day'
      }],
      refill_periods: [{
        name: '每年自動發放',
        value: 'year'
      }, {
        name: '每月自動發放',
        value: 'month'
      }],
      expires: [{
        name: '自發放起一個周期',
        value: 'period'
      }, {
        name: '永久有效',
        value: 'unlimited'
      }]
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.isEdit = true;
      this.fetchData();
    } else {
      this.buttonText = 'Add';
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _leave.getLeave)(this.$route.params.id).then(response => {
        this.leave = response.data;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          this.loading = true;
          if (this.$route.params.id) {
            (0, _leave.editLeave)(this.leave).then(() => {
              (0, _elementUi.Message)({
                message: 'Leave updated.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/leaves`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              // console.log(err)
            }).finally(() => {
              this.loading = false;
            });
          } else {
            (0, _leave.createLeave)(this.leave).then(() => {
              (0, _elementUi.Message)({
                message: 'Leave created.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/leaves`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
            }).finally(() => {
              this.loading = false;
            });
          }
        }
      });
    },
    onCancel() {
      this.$router.push(`/leaves`);
    }
  }
};
exports.default = _default;