"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _leave = require("@/api/leave");
var _elementUi = require("element-ui");
var _errorParse = require("@/utils/error-parse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0,
      currentUser: null
    };
  },
  async created() {
    this.listLoading = true;
    (0, _leave.getHistory)({
      leave_id: this.$route.params.id,
      user_id: this.$route.params.user_id,
      listAll: true
    }).then(response => {
      this.list = response.data;
    }).finally(() => {
      this.listLoading = false;
    });
  },
  methods: {
    editApply(uuid) {
      if (this.$route.params.user_id) {
        this.$router.push(`/users/${this.$route.params.user_id}/leaves/apply/edit/` + uuid);
      } else {
        this.$router.push(`/leaves/apply/edit/` + uuid);
      }
    },
    deleteApply(uuid) {
      return _elementUi.MessageBox.confirm('Confirm to delete Leave apply record?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _leave.deleteApplyHistory)(uuid).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Leave apply deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));

      // this.$router.push(`/leaves/apply/edit/`+uuid)
    },

    applyLeaveDate(valObj) {
      return valObj.date_from.split(' ')[0] + ' ' + valObj.periodfrom + ' - ' + valObj.date_to.split(' ')[0] + ' ' + valObj.periodto;
    }
  }
};
exports.default = _default;