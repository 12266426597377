"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vuex = require("vuex");
var _errorParse = require("@/utils/error-parse");
var _leave = require("@/api/leave");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user'])
  },
  async created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.listLoading = true;
      (0, _leave.getLeaves)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },
    editLeave(id) {
      this.$router.push(`/leaves/edit/${id}`);
    },
    leaveForStaff(id) {
      this.$router.push(`/leaves/staff/${id}`);
    },
    deleteleave(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete Leave?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _leave.deleteLeave)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Leave deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }
  }
};
exports.default = _default;